/** @jsx jsx */
import { jsx } from "@emotion/core"
import moment from "moment"
import { Button, Box } from "theme-ui"
import { Layout } from "../components/Layout"
import { graphql, Link } from "gatsby"
import { eventCheckInPath } from "../util/paths"

export const pageQuery = graphql`
  query IndexPageQuery {
    allActionNetworkEvent(filter: { status: { eq: "confirmed" } }) {
      edges {
        node {
          startDate
          shortCode
          id
          title
          status
        }
      }
    }
  }
`

export default ({ data }) => {
  const {
    allActionNetworkEvent: { edges: events },
  } = data

  const upcomingEvents = events.filter(e =>
    moment(e.node.startDate).isSame(moment(), "days")
  )

  return (
    <Layout title="Today">
      <Box as="ul" sx={{ listStyle: "none", m: 0, width: "100%" }}>
        {(upcomingEvents as any[]).map(({ node }) => {
          return (
            <Link key={node.id} to={eventCheckInPath(node.shortCode)}>
              <Button variant={"outline"} sx={{ width: "100%" }}>
                {node.title}
              </Button>
            </Link>
          )
        })}
      </Box>
    </Layout>
  )
}
